.text__banner-wrapper {
  padding: 7.2rem 0;
  background-color: var(--black2);
}

.text__banner {
  margin: 0 -8.85rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.text__banner-content {
  width: calc((100% / 3) - 17.7rem);
  max-width: 26.4rem;
  margin: 0 8.85rem;
}

.text__banner-content > * {
  font: normal 1.6rem / 2rem 'Days One', sans-serif;
  color: var(--green1);
  text-align: center;
}

.companys__blocks-wrapper {
  position: relative;
  padding: 24.6rem 0 7rem;
  background-color: var(--white2);
}

.companys__blocks-wrapper.with_ttl {
  padding: 10rem 0 7rem;
}

.companys__blocks-wrapper.with_ttl .companys__ttl,
.companys__blocks-wrapper.with_ttl .companys__subttl {
  display: block;
}

.companys__blocks-wrapper > .wrapper {
  max-width: 98.2rem;
}

.companys__img {
  position: absolute;
  opacity: 0.5;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.companys__img.round1 {
  width: 2.4rem;
  height: 2.4rem;
  top: 7.6rem;
  left: 40%;
  transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-50%);
}

.companys__img.round2 {
  width: 4.6rem;
  height: 4.6rem;
  bottom: 15.9rem;
  right: 38.6rem;
}

.companys__img.round3 {
  width: 3.6rem;
  height: 3.6rem;
  bottom: 5rem;
  left: 38.6rem;
}

.companys__ttl, .companys__subttl {
  display: none;
}

.companys__ttl {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto 6rem;
  padding: 0 1.6rem;
}

.companys__subttl {
  max-width: 75rem;
  margin-bottom: 6rem;
}

.companys__blocks {
  position: relative;
  z-index: 1;
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.companys__block {
  width: calc((100% / 5) - 3rem);
  max-width: 16.8rem;
  margin: 0 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.companys__block-icon {
  width: 10rem;
  height: 10rem;
  margin-bottom: -5.3rem;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 1rem solid var(--white2);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.companys__block-icon .icon__wrapper {
  max-width: 6rem;
  max-height: 4rem;
}

.companys__block-content {
  padding-top: 6.9rem;
  background-color: var(--white);
  border-radius: 2rem;
  box-shadow: 0.4rem 4rem 5rem rgba(160, 112, 112, 0.15);
  backdrop-filter: blur(2rem);
}

.companys__block-content .content__info {
  margin-bottom: 1.9rem;
  padding: 0 1.8rem;
}

.companys__block-content .content__info > * {
  font: 500 1rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
  text-align: center;
}

.companys__block-content .content__link-wrapper {
  padding: 1.3rem;
  background-color: var(--green3);
  border-radius: 0 0 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companys__block-content .content__link-wrapper > * {
  position: relative;
  padding-right: 1.1rem;
  font: 600 1.2rem / 1.5rem 'Montserrat', sans-serif;
  color: var(--green1);
  transition: all 0.3s linear;
}

.companys__block-content .content__link-wrapper > *:hover {
  color: var(--blue2);
}

.companys__block-content .content__link-wrapper > *:hover::after {
  background: url("../img/icons/i-link-arr-blue.svg") center/contain no-repeat;
}

.companys__block-content .content__link-wrapper > *::after {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s linear;
  background: url("../img/icons/i-link-arr.svg") center/contain no-repeat;
}

.customers__first {
  position: relative;
  padding: 12rem 0 9rem;
  overflow: hidden;
}

.customers__first.inited_slider {
  padding: 7rem 0 13.3rem;
  background-color: var(--white2);
}

.customers__first.inited_slider .customers__first-slider-arr {
  display: flex;
}

.customers__first-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.customers__first-ttl {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 1.6rem;
}

.customers__first-slider-wrapper {
  width: 100%;
  max-width: 117.8rem;
  margin: 0 auto;
  padding: 0 1.6rem;
  position: relative;
  z-index: 1;
}

.customers__first-slider-wrapper .customers__first-slider__decor {
  position: absolute;
  left: -7rem;
  top: -6rem;
  width: 20rem;
  height: 20rem;
}

.customers__first-slider-wrapper .customers__first-slider__decor img {
  display: block;
  width: 100%;
}

@media (max-width: 666px) {
  .customers__first-slider-wrapper .customers__first-slider__decor {
    display: none;
  }
}

.customers__first-slider-wrapper.init_blocks .customers__first-slider {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.customers__first-slider-wrapper.init_blocks .customers__first-slider > * {
  width: calc((100% / 3) - 3rem);
  max-width: 36.2rem;
  margin: 0 1.5rem 3rem;
}

.customers__first-slider-wrapper.init_slider .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.customers__first-slider-wrapper.init_slider .slick-track {
  padding-bottom: 9rem;
}

.customers__first-slider-wrapper.init_slider .slick-dots {
  bottom: 3.2rem;
}

.customers__first-slider .slide {
  position: relative;
  width: 100%;
  min-height: 33rem;
  max-width: 36.2rem;
  margin: 0 auto;
  padding: 3rem;
  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
  background-color: var(--white);
  border-radius: 1rem;
}

.customers__first-slider .slide > *:not(:last-child) {
  margin-bottom: 2rem;
}

.customers__first-slider .slide__icon {
  width: 5rem;
  height: 5rem;
  padding: 1rem;
  border-radius: 0.8rem;
  background-color: var(--light-green3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.customers__first-slider .slide__icon > * {
  width: 3rem;
  height: 3rem;
}

.customers__first-slider .slide__ttl {
  min-height: 4rem;
  display: flex;
  align-items: center;
}

.customers__first-slider .slide__ttl > * {
  font: normal 1.6rem / 2rem 'Days One', sans-serif;
  color: var(--blue1);
}

.customers__first-slider .slide__content > *:not(:last-child) {
  margin-bottom: 2rem;
}

.customers__first-slider .slide__content > p {
  min-height: 5.1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  color: var(--silver3);
}

.customers__first-slider .slide__content > ul > li {
  position: relative;
  padding-left: 1.6rem;
  font: 500 1.4rem / 1.5 'Montserrat', sans-serif;
  color: var(--silver4);
}

.customers__first-slider .slide__content > ul > li::before {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  position: absolute;
  top: .7rem;
  left: 0;
  border-radius: 50%;
  background-color: var(--green1);
}

.customers__first-slider .slide__content > ul > li:not(:last-child) {
  margin-bottom: 1.2rem;
}

.customers__first-slider-arr {
  position: absolute;
  bottom: 3.2rem;
  right: 2.8rem;
  display: none;
}

.customers__first.li-uppercase li {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.green__blocks {
  position: relative;
  padding: 10rem 0 8.8rem;
  background-color: var(--white2);
}

.green__blocks-img {
  position: absolute;
}

.green__blocks-img.round1, .green__blocks-img.round2, .green__blocks-img.round3 {
  opacity: 0.5;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
}

.green__blocks-img.round1 {
  width: 3rem;
  height: 3rem;
  top: 17.1rem;
  left: 60%;
  transform: translateX(-50%);
}

.green__blocks-img.round2 {
  width: 10rem;
  height: 10rem;
  bottom: 14.5rem;
  right: 35.5rem;
}

.green__blocks-img.round3 {
  width: 5rem;
  height: 5rem;
  top: 26.6rem;
  left: 25.6rem;
}

.green__blocks-img.lines {
  width: 100%;
  max-width: 46.9rem;
  bottom: 0;
  left: 0;
}

.green__blocks-ttl {
  width: 100%;
  max-width: 55.8rem;
  margin: 0 auto 6rem;
  padding: 0 1.6rem;
}

.green__blocks-slider-wrapper {
  width: 100%;
  max-width: 117.8rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.green__blocks-slider-wrapper.init_slider .slick-dotted.slick-slider {
  margin: 0;
}

.green__blocks-slider-wrapper.init_slider .slick-track {
  padding-bottom: 9rem;
}

.green__blocks-slider-wrapper.init_slider .slick-dots {
  bottom: 3.2rem;
}

.green__blocks-slider-wrapper.init_blocks .green__blocks-slider {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.green__blocks-slider-wrapper.init_blocks .green__blocks-slider > * {
  width: calc((100% / 4) - 3rem);
  max-width: 26.4rem;
  margin: 0 1.5rem;
}

.green__blocks-slider {
  position: relative;
  z-index: 1;
}

.green__blocks-slider .slide {
  position: relative;
  width: 100%;
  max-width: 26.4rem;
  margin: 0 auto;
  padding: 10rem 3rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2rem;
  background-color: var(--light-green2);
  box-shadow: 1rem 7rem 6rem -3.2rem rgba(88, 185, 71, 0.2);
  backdrop-filter: blur(2rem);
  overflow: hidden;
}

.green__blocks-slider .slide::before {
  content: '';
  width: 15.4rem;
  height: 15.4rem;
  position: absolute;
}

.green__blocks-slider .slide::after {
  content: '';
  width: 20.8rem;
  height: 20.8rem;
  position: absolute;
}

.green__blocks-slider .slide.first::after {
  bottom: 0;
  right: 0;
  background: url("../img/green-slide-round1.svg") center/contain no-repeat;
}

.green__blocks-slider .slide.second::after {
  top: -3rem;
  right: -3rem;
  background: url("../img/green-slide-round2.svg") center/contain no-repeat;
}

.green__blocks-slider .slide.third::after {
  width: 20rem;
  height: 20rem;
  bottom: -4rem;
  right: -5.5rem;
  background: url("../img/green-slide-round3.svg") center/contain no-repeat;
}

.green__blocks-slider .slide.fourth::before {
  top: -6rem;
  right: -4rem;
  background: url("../img/green-slide-round5.svg") center/contain no-repeat;
}

.green__blocks-slider .slide.fourth::after {
  width: 21.2rem;
  height: 21.2rem;
  bottom: -6rem;
  left: -3rem;
  background: url("../img/green-slide-round4.svg") center/contain no-repeat;
}

.green__blocks-slider .slide__ttl {
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
}

.green__blocks-slider .slide__ttl > * {
  font: normal 1.8rem / 2.3rem 'Days One', sans-serif;
  color: var(--blue1);
}

.green__blocks-slider .slide__more {
  position: relative;
  z-index: 1;
  padding-right: 1.5rem;
  font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
  color: var(--green1);
  transition: all 0.3s linear;
}

.green__blocks-slider .slide__more:hover {
  color: var(--blue2);
}

.green__blocks-slider .slide__more:hover::after {
  background: url("../img/icons/i-link-arr-blue.svg") center/contain no-repeat;
}

.green__blocks-slider .slide__more::after {
  content: '';
  width: 0.5rem;
  height: 0.8rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s linear;
  background: url("../img/icons/i-link-arr.svg") center/contain no-repeat;
}

.green__blocks-slider .slide__icon {
  position: relative;
  z-index: 1;
  width: 7rem;
  height: 7rem;
  margin: 5.9rem 1rem 0 0;
  align-self: flex-end;
}

.testimonials__wrapper {
  position: relative;
  padding: 10rem 0 15.3rem;
}

.testimonials__img {
  position: absolute;
  z-index: -1;
}

.testimonials__img.round1, .testimonials__img.round2 {
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  opacity: 0.5;
}

.testimonials__img.round1 {
  width: 3rem;
  height: 3rem;
  top: 15.2rem;
  right: 27.6rem;
}

.testimonials__img.round2 {
  width: 8rem;
  height: 8rem;
  bottom: 12rem;
  left: 34rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.testimonials__img.line {
  width: 100%;
  height: 60%;
  top: 0;
  right: 0;
}

.testimonials__ttl {
  margin-bottom: 6rem;
}

.testimonials__slider-wrapper {
  width: 100%;
  max-width: 154.2rem;
  margin-left: auto;
  position: relative;
}

.testimonials__slider {
  width: 100%;
  max-width: 154.2rem;
  margin-left: auto;
}

.testimonials__slider .slide {
  display: flex;
  align-items: flex-start;
}

.testimonials__slider .slide__video {
  position: relative;
  width: 100%;
  max-width: 65.5rem;
  height: 44.9rem;
  margin-right: 3rem;
  border-radius: 2rem;
}

.testimonials__slider .slide__video-poster {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.testimonials__slider .slide__video-play {
  width: 10rem;
  height: 10rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.testimonials__slider .slide__video > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.testimonials__slider .slide__video > *,
.testimonials__slider .slide__video > * > * {
  border-radius: inherit;
}

.testimonials__slider .slide__content {
  width: 100%;
  max-width: 45.9rem;
}

.testimonials__slider .slide__content-name {
  margin-bottom: 1rem;
}

.testimonials__slider .slide__content-name > * {
  font: 600 1.8rem / 2.2rem 'Montserrat', sans-serif;
  color: var(--blue1);
}

.testimonials__slider .slide__content-position {
  margin-bottom: 2rem;
}

.testimonials__slider .slide__content-position > * {
  font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;
  color: var(--silver3);
}

.testimonials__slider .slide__content-text > * {
  font: italic 500 1.8rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
}

.testimonials__navigation {
  width: 100%;
  max-width: 85rem;
  position: absolute;
  bottom: -5.3rem;
  right: 0;
}

.testimonials__navigation .slide {
  width: 100%;
  max-width: 26.4rem;
}

.testimonials__navigation .slide__poster {
  width: 100%;
  max-width: 26.4rem;
  height: 14.8rem;
  position: relative;
  border-radius: 0.8rem;
  margin-bottom: 1.4rem;
  cursor: pointer;
}

.testimonials__navigation .slide__poster::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  background-color: var(--white);
  opacity: 0.5;
}

.testimonials__navigation .slide__poster-play {
  width: 8rem;
  height: 8rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.testimonials__navigation .slide__poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
  border-radius: inherit;
}

.testimonials__navigation .slide__name {
  margin-bottom: 1rem;
}

.testimonials__navigation .slide__name > * {
  font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
  color: var(--blue1);
}

.testimonials__navigation .slide__position > * {
  font: normal 1rem / 1.2rem 'Montserrat', sans-serif;
  color: var(--silver3);
}

.testimonials__slider-arr {
  position: absolute;
  bottom: -5.3rem;
  left: 61.5rem;
}

.resources__wrapper {
  position: relative;
  padding: 10rem 0 6.8rem;
  background-color: var(--white2);
}

.resources__bg {
  position: absolute;
  z-index: 1;
}

.resources__bg.round1, .resources__bg.round2, .resources__bg.round3 {
  opacity: 0.5;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
}

.resources__bg.round1 {
  width: 2.8rem;
  height: 2.8rem;
  top: 6.5rem;
  left: 56%;
  transform: translateX(-50%) rotate(-180deg);
}

.resources__bg.round2 {
  width: 6.4rem;
  height: 6.4rem;
  bottom: 21.3rem;
  right: 28.9rem;
}

.resources__bg.round3 {
  width: 2rem;
  height: 2rem;
  top: 39.3rem;
  left: 26.1rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.resources__bg.img1, .resources__bg.img2 {
  width: 100%;
  height: 100%;
  max-width: 20.4rem;
  max-height: 20.4rem;
}

.resources__bg.img1 {
  top: 19rem;
  right: 28.2rem;
}

.resources__bg.img2 {
  bottom: 10.8rem;
  left: 27.4rem;
}

.resources__ttl {
  margin-bottom: 4.7rem;
  padding: 0 1.6rem;
}

.resources__navigation {
  position: relative;
  z-index: 1;
  margin-bottom: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid var(--white);
}

.resources__navigation > * {
  position: relative;
  margin: 0 4rem;
  padding-bottom: 2.5rem;
  transition: all 0.3s linear;
  color: var(--silver3);
  text-align: center;
}

.resources__navigation > *::after {
  content: '';
  width: 0;
  height: 0.2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s linear;
  background-color: var(--green1);
}

.resources__navigation > *.active, .resources__navigation > *:hover {
  color: var(--green1);
}

.resources__navigation > *.active::after, .resources__navigation > *:hover::after {
  width: 12rem;
}

.resources__navigation > *.active {
  cursor: default;
}

.resources__blocks {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 122.8rem;
  min-height: 63rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.resources__block {
  display: none;
}

.resources__block.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.resources__block .slick-list {
  padding-bottom: 9rem;
}

.resources__block .slick-dots {
  bottom: 3.2rem;
}

.resources__block .block {
  width: 100%;
  max-width: 36.2rem;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 2rem;
  box-shadow: 10px 70px 60px -32px var(--light-green);
  backdrop-filter: blur(2rem);
}

.resources__block .block__img {
  border-radius: 2rem 2rem 0 0;
}

.resources__block .block__img > * {
  border-radius: inherit;
}

.resources__block .block__content-wrapper {
  padding: 3rem;
}

.resources__block .block__subttl {
  margin-bottom: 2rem;
}

.resources__block .block__subttl > * {
  font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;
  color: var(--silver4);
  text-transform: uppercase;
}

.resources__block .block__ttl {
  margin-bottom: 1.4rem;
  display: flex;
}

.resources__block .block__ttl > * {
  font: normal 1.8rem / 150% 'Days One', sans-serif;
  color: var(--blue1);
  text-transform: uppercase;
}

.resources__block .block__text {
  margin-bottom: 2.4rem;
}

.resources__block .block__text > * {
  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
}

.resources__block .block__info {
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resources__block .block__info > * {
  position: relative;
  padding-left: 2.6rem;
  font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
  color: var(--silver4);
  text-decoration: underline;
}

.resources__block .block__info > *::before {
  content: '';
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.resources__block .block__info > *.date::before {
  background: url("../img/icons/i-calendar.svg") center/contain no-repeat;
}

.resources__block .block__info > *.time::before {
  background: url("../img/icons/i-clock.svg") center/contain no-repeat;
}

.resources__block .block__more {
  position: relative;
  padding-right: 1.5rem;
  font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
  color: var(--green1);
  transition: all 0.3s linear;
}

.resources__block .block__more:hover {
  color: var(--blue2);
}

.resources__block .block__more:hover::after {
  background: url("../img/icons/i-link-arr-blue.svg") center/contain no-repeat;
}

.resources__block .block__more::after {
  content: '';
  width: 0.5rem;
  height: 0.8rem;
  position: absolute;
  right: 0;
  top: 53%;
  transform: translateY(-50%);
  transition: all 0.3s linear;
  background: url("../img/icons/i-link-arr.svg") center/contain no-repeat;
}

.text__blocks {
  position: relative;
  padding: 12rem 0 9.3rem;
}

.text__blocks.white_blocks {
  padding: 8rem 0 7.3rem;
  background-color: var(--white2);
}

.text__blocks.white_blocks .text__blocks-ttl {
  max-width: 88.2rem;
  margin: 0 auto 6rem;
}

.text__blocks.white_blocks .text__blocks-slider .slide {
  max-width: 26.4rem;
  min-height: 15rem;
  padding: 4.8rem 3rem;
}

.text__blocks.white_blocks .text__blocks-slider .slide__content {
  width: 100%;
  max-width: 20.4rem;
  margin: 0 auto;
}

.text__blocks.white_blocks .text__blocks-slider .slide__content > * {
  font: 600 1.8rem / 150% 'Montserrat', sans-serif;
  color: var(--blue1);
}

.text__blocks.green_blocks {
  padding: 10rem 0 9.3rem;
}

.text__blocks.green_blocks .text__blocks-ttl {
  margin: 0 auto 4rem;
}

.text__blocks.green_blocks .text__blocks-subttl > * {
  font-size: 1.6rem;
}

.text__blocks.green_blocks .text__blocks-slider .slide {
  max-width: 26.4rem;
  min-height: 14rem;
  padding: 4.3rem 4rem;
  background-color: var(--light-green2);
}

.text__blocks.green_blocks .text__blocks-slider .slide.first::after, .text__blocks.green_blocks .text__blocks-slider .slide.second::after, .text__blocks.green_blocks .text__blocks-slider .slide.third::after, .text__blocks.green_blocks .text__blocks-slider .slide.fourth::after {
  content: '';
  position: absolute;
}

.text__blocks.green_blocks .text__blocks-slider .slide.first::after {
  bottom: 0;
  right: 0;
  width: 13.7rem;
  height: 13.7rem;
  background: url("../img/text-blocks-green-round1.svg") center/contain no-repeat;
}

.text__blocks.green_blocks .text__blocks-slider .slide.second::after {
  top: 0;
  left: 0;
  width: 10rem;
  height: 13.7rem;
  background: url("../img/text-blocks-green-round2.svg") center/contain no-repeat;
}

.text__blocks.green_blocks .text__blocks-slider .slide.third::after {
  bottom: 0;
  right: 0;
  width: 13.7rem;
  height: 7rem;
  background: url("../img/text-blocks-green-round3.svg") center/contain no-repeat;
}

.text__blocks.green_blocks .text__blocks-slider .slide.fourth::after {
  top: 0;
  right: 0;
  width: 13.7rem;
  height: 13rem;
  background: url("../img/text-blocks-green-round4.svg") center/contain no-repeat;
}

.text__blocks.green_blocks .text__blocks-slider .slide__content > * {
  font: normal 1.8rem / 150% 'Days One', sans-serif;
  color: var(--blue1);
}

.text__blocks-bg {
  width: 100%;
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
}

.text__blocks-ttl, .text__blocks-subttl {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 75.4rem;
  padding: 0 1.6rem;
}

.text__blocks-ttl {
  margin: 0 auto 3rem;
}

.text__blocks-subttl {
  max-width: 70rem;
  margin: 0 auto 6rem;
}

.text__blocks-subttl > * {
  font-size: 2rem;
}

.text__blocks-slider-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 122.5rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.text__blocks-slider-img {
  position: absolute;
}

.text__blocks-slider-img.round {
  bottom: -.5rem;
  left: -5rem;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  opacity: 0.5;
}

.text__blocks-slider-img.line_round {
  top: -10.8rem;
  right: -7.7rem;
  width: 25rem;
  height: 25rem;
}

.text__blocks-slider .slick-list {
  padding-bottom: 6rem;
}

.text__blocks-slider .slide {
  position: relative;
  width: 100%;
  max-width: 36.2rem;
  min-height: 26rem;
  margin: 0 auto;
  padding: 6.7rem 6.8rem;
  background-color: var(--white);
  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.08);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text__blocks-slider .slide__content > * {
  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
  text-align: center;
}

.text__blocks-slider-arr {
  position: absolute;
  bottom: 0.7rem;
  right: 3.6rem;
}

.with__icons-wrapper {
  position: relative;
  padding: 10rem 0 7rem;
  background-color: var(--white2);
  overflow: hidden;
}

.with__icons-wrapper.bg_white {
  background-color: var(--white);
}

.with__icons-wrapper.bg_white .with__icons-ttl {
  max-width: 81.4rem;
  margin: 0 auto 4rem;
}

.with__icons-wrapper.bg_white .with__icons-wrapper-bg {
  top: 0;
  left: 0;
  bottom: auto;
  width: 100%;
  height: 100%;
}

.with__icons-wrapper-bg {
  position: absolute;
  bottom: -17rem;
  left: -8rem;
  width: 35.8rem;
  height: 35.8rem;
}

.with__icons-ttl {
  width: 100%;
  max-width: 65.4rem;
  margin: 0 auto 6.5rem;
}

.with__icons-subttl {
  width: 100%;
  max-width: 65.1rem;
  margin: 0 auto 6rem;
}

.with__icons-blocks {
  position: relative;
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.with__icons-blocks-round {
  position: absolute;
  top: -6.4rem;
  right: -6.3rem;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background: linear-gradient(305.73deg, rgba(105, 244, 80, 0.2) 16.66%, rgba(255, 255, 255, 0) 96.27%);
  opacity: 0.6;
}

.with__icons-blocks .block {
  width: calc((100% / 4) - 3rem);
  margin: 0 1.5rem 3rem;
  padding: 3rem;
  background-color: var(--white);
  box-shadow: 0.4rem 4rem 5rem rgba(88, 185, 71, 0.15);
  backdrop-filter: blur(2rem);
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.with__icons-blocks .block__icon {
  width: 100%;
  max-width: 12rem;
  height: 12rem;
  margin-bottom: 3rem;
  border-radius: 1.2rem;
  background-color: var(--light-green2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.with__icons-blocks .block__icon > * {
  width: 5rem;
  height: 5rem;
}

.with__icons-blocks .block__content > * {
  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: var(--blue1);
}

.text__desc-wrapper {
  padding: 10rem 0;
}

.text__desc-wrapper.desc_reverse {
  background-color: var(--white);
}

.text__desc-wrapper.desc_reverse .text__desc {
  flex-direction: row-reverse;
}

.text__desc-wrapper.desc_reverse .text__desc-content {
  margin-right: 0;
  margin-left: 1.9rem;
}

.text__desc-wrapper.desc_reverse .text__desc-img {
  max-width: 67rem;
  margin-right: 0;
  margin-left: -10rem;
}

.text__desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text__desc-content {
  width: 100%;
  max-width: 55.8rem;
  margin-right: 1.9rem;
}

.text__desc-content > *:not(:last-child) {
  margin-bottom: 2.5rem;
}

.text__desc-content > h2 {
  font: bold 3.4rem / 4rem 'Work Sans', sans-serif;
  letter-spacing: -0.02em;
  color: var(--black2);
}

.text__desc-content > h3 {
  font: normal 2.4rem / 150% 'Days One', sans-serif;
  color: var(--blue1);
}

.text__desc-content > h5 {
  font: normal 1.6rem / 2rem 'Days One', sans-serif;
  letter-spacing: -0.02em;
  color: var(--blue1);
}

.text__desc-content > h6 {
  font: 500 1.6rem / 2rem 'Montserrat', sans-serif;
  letter-spacing: -0.02em;
  color: var(--silver3);
}

.text__desc-content > p {
  font: 500 1.6rem / 150% 'Montserrat', sans-serif;
  color: var(--blue1);
}

.text__desc-content > ul > li {
  position: relative;
  padding-left: 1.8rem;
  font: normal 1.4rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
}

.text__desc-content > ul > li::before {
  content: '';
  position: absolute;
  top: .5rem;
  left: 0;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: var(--green1);
}

.text__desc-content > ul > li:not(:last-child) {
  margin-bottom: 2.5rem;
}

.text__desc-img {
  width: 100%;
  max-width: 60rem;
  margin-right: -3.1rem;
}

.banner {
  position: relative;
  padding: 10rem 0;
}

.banner.with_img {
  padding: 8rem 0;
}

.banner.with_img .banner__content {
  max-width: 78.6rem;
}

.banner.with_img .banner__content-ttl {
  margin-bottom: 3rem;
}

.banner.with_img .banner__content-txt {
  width: 100%;
  max-width: 55.8rem;
  margin: 0 auto 4rem;
}

.banner.with_img .banner__content-txt > * {
  font: 500 1.6rem / 150% 'Montserrat', sans-serif;
}

.banner__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner__img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black5);
}

.banner__video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner__video.active {
  position: fixed;
  z-index: 5;
}

.banner__video.active::after {
  display: none;
}

.banner__video.active .banner__video-close {
  opacity: 1;
  pointer-events: visible;
}

.banner__video::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black5);
}

.banner__video-close {
  position: absolute;
  z-index: 1;
  top: 4rem;
  right: 20%;
  width: 4rem;
  height: 4rem;
  opacity: 0;
  pointer-events: none;
}

.banner__content {
  width: 100%;
  max-width: 68.6rem;
  margin: 0 auto;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner__content-ttl > *, .banner__content-txt > * {
  color: var(--white);
}

.banner__content-ttl {
  margin-bottom: 4rem;
}

.banner__content-ttl > * mark {
  color: var(--green1);
}

.banner__content-subttl > * {
  font: bold 1.8rem / 150% 'Montserrat', sans-serif;
  color: var(--white);
  text-align: center;
}

.banner__content-txt {
  margin-bottom: 7rem;
}

.banner__content-txt > * {
  font: 500 2rem / 2.4rem 'Montserrat', sans-serif;
  letter-spacing: -0.02em;
  text-align: center;
}

.miss__wrapper {
  padding: 10rem 0 7rem;
  overflow: hidden;
}

.miss__top {
  margin-bottom: 6rem;
}

.miss__ttl {
  position: relative;
  width: 100%;
  max-width: 69rem;
  margin: 0 auto 4rem;
}

.miss__ttl-round {
  position: absolute;
  z-index: -1;
  top: -3.4rem;
  left: -0.8rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  opacity: 0.5;
  transform: rotate(-180deg);
}

.miss__ttl-img {
  position: absolute;
  width: 8rem;
  height: 8rem;
  left: -6rem;
  top: -4rem;
}

.miss__ttl-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.miss__blocks {
  position: relative;
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.miss__blocks-bg {
  position: absolute;
  z-index: -1;
  top: -10rem;
  right: -9.5rem;
  width: 30rem;
  height: 30rem;
}

.miss__block {
  width: calc((100% / 2) - 3rem);
  margin: 0 1.5rem 3rem;
  padding: 4rem;
  background-color: var(--white);
  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.miss__block-img {
  width: 100%;
  margin-bottom: 3rem;
}

.miss__block-img > * {
  width: 100%;
  max-width: 47.8rem;
  max-height: 27rem;
}

.miss__block-ttl {
  margin-bottom: 2rem;
}

.miss__block-ttl > * {
  font: normal 1.6rem / 2rem 'Days One', sans-serif;
  color: var(--blue1);
  text-align: center;
}

.miss__block-txt > * {
  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
  text-align: center;
}

.miss--2 .miss__ttl {
  margin-bottom: 4rem;
}

.miss--2 .miss__block-ttl {
  order: -1;
}

.miss--2 .miss__blocks-bg {
  width: 25rem;
  height: 25rem;
}

.small__text-wrapper {
  position: relative;
  padding: 8rem 0;
  background-color: var(--white2);
  overflow: hidden;
}

.small__text {
  max-width: 98.2rem;
}

.small__text-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.small__text > * {
  font: normal 3.6rem / 4.6rem 'Days One', sans-serif;
  color: var(--black2);
  text-align: center;
}

.several__descs-wrapper {
  position: relative;
  padding: 10rem 0;
}

.several__descs-wrapper.bg_green {
  background-color: var(--white2);
}

.several__descs-wrapper.several_reverse .several__descs-bg.round1 {
  width: 5rem;
  height: 5rem;
  top: 7rem;
  left: 16.7%;
  right: auto;
  transform: rotate(-180deg);
}

.several__descs-wrapper.several_reverse .several__descs-bg.round2 {
  top: 52%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.several__descs-wrapper.several_reverse .several__descs-bg.round3 {
  width: 7rem;
  height: 7rem;
  top: 19.9rem;
  bottom: auto;
  right: 21%;
  transform: unset;
}

.several__descs-wrapper.several_reverse .several__descs-bg.round4 {
  width: 10rem;
  height: 10rem;
  bottom: 6.9rem;
  right: 16%;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.several__descs-wrapper.several_reverse .several__descs-blocks > *:not(:last-child) {
  margin-bottom: 10rem;
}

.several__descs-wrapper.several_reverse .text__desc {
  flex-direction: row-reverse;
}

.several__descs-wrapper.several_reverse .text__desc-content {
  margin-right: 0;
  margin-left: 9.8rem;
}

.several__descs-wrapper.several_reverse .text__desc:nth-child(even) {
  flex-direction: row;
}

.several__descs-wrapper.several_reverse .text__desc:nth-child(even) .text__desc-img {
  margin-right: -11rem;
  margin-left: 0;
}

.several__descs-wrapper.several_reverse .text__desc:nth-child(even) .text__desc-content {
  margin-right: 9.8rem;
  margin-left: 0;
}

.several__descs-bg {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  opacity: 0.5;
}

.several__descs-bg.round1, .several__descs-bg.round3 {
  width: 6rem;
  height: 6rem;
}

.several__descs-bg.round1 {
  top: 14.7rem;
  right: 23%;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.several__descs-bg.round2 {
  top: 50%;
  left: 17.2rem;
  transform: translateY(-50%) matrix(-1, 0, 0, 1, 0, 0);
  width: 10rem;
  height: 10rem;
}

.several__descs-bg.round3 {
  bottom: 6.4rem;
  right: 15%;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.several__descs-ttl {
  width: 100%;
  max-width: 75.2rem;
  margin: 0 auto 6rem;
}

.several__descs-blocks {
  display: flex;
  flex-direction: column;
}

.several__descs-blocks > *:not(:last-child) {
  margin-bottom: 6rem;
}

.several__descs-blocks > *:nth-child(even) {
  flex-direction: row-reverse;
}

.several__descs-blocks > *:nth-child(even) .text__desc-content {
  margin-right: 0;
  margin-left: 9.8rem;
  max-width: 46rem;
}

.several__descs-blocks > *:nth-child(even) .text__desc-img {
  margin-right: 0;
  margin-left: -1.2rem;
}

.several__descs-blocks .text__desc-content {
  margin-right: 9.8rem;
  max-width: 46rem;
}

.several__descs-blocks .text__desc-img {
  margin-right: -1.2rem;
}

.text__testimonials-wrapper {
  position: relative;
  padding: 8rem 0 5.3rem;
  overflow: hidden;
}

.text__testimonials-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}

.text__testimonials-ttl {
  position: relative;
  width: 100%;
  max-width: 20.5rem;
  padding: 0 1.6rem;
  margin: 0 auto 6rem;
}

.text__testimonials-ttl-round {
  position: absolute;
  z-index: -1;
  top: -4.8rem;
  left: -4.1rem;
  width: 12rem;
  height: 12rem;
}

.text__testimonials-slider-wrapper {
  position: relative;
  width: 100%;
  max-width: 121rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.text__testimonials-slider-wrapper .slick-track {
  padding-bottom: 8rem;
}

.text__testimonials-slider-arr {
  position: absolute;
  z-index: 1;
  bottom: 2.7rem;
  right: 3.2rem;
}

.text__testimonials-slider .slide {
  width: 100%;
  max-width: 26.4rem;
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0.5rem 3rem 2rem rgba(88, 185, 71, 0.15);
  backdrop-filter: blur(2rem);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text__testimonials-slider .slide:hover .slide__quote svg path {
  fill: var(--green4);
}

.text__testimonials-slider .slide > *:not(:last-child) {
  margin-bottom: 2rem;
}

.text__testimonials-slider .slide__quote {
  padding: 3rem 0 0 3rem;
}

.text__testimonials-slider .slide__quote svg path {
  transition: all .3s linear;
}

.text__testimonials-slider .slide__content {
  min-height: 8.4rem;
  padding: 0 2rem;
}

.text__testimonials-slider .slide__content > * {
  font: normal 1.4rem / 150% 'Montserrat', sans-serif;
  color: var(--silver3);
}

.text__testimonials-slider .slide__more {
  padding: 0 2rem;
  font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
  text-decoration: underline;
  color: var(--green5);
  align-self: flex-end;
  transition: all .3s linear;
  cursor: pointer;
}

.text__testimonials-slider .slide__more:hover {
  color: var(--green1);
}

.text__testimonials-slider .slide__person {
  width: 100%;
  padding: 1.5rem 2rem 1.7rem;
  display: flex;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  background-color: var(--green6);
}

.text__testimonials-slider .slide__person-img {
  width: 100%;
  max-width: 3.8rem;
  min-width: 3.8rem;
  height: 3.8rem;
  margin-right: 2.1rem;
  border-radius: 50%;
}

.text__testimonials-slider .slide__person-img > * {
  border-radius: inherit;
}

.text__testimonials-slider .slide__person-info {
  display: flex;
  flex-direction: column;
}

.text__testimonials-slider .slide__person-info .info__name {
  margin-bottom: 0.5rem;
  font: normal 1.6rem / 2rem 'Days One', sans-serif;
  color: var(--blue1);
}

.text__testimonials-slider .slide__person-info .info__position {
  font: 500 1.1rem / 1.3rem 'Montserrat', sans-serif;
  color: var(--silver3);
}

.integration__wrapper {
  position: relative;
  padding: 10rem 0 7rem;
}

.integration__ttl {
  margin-bottom: 6rem;
}

.integration__blocks {
  position: relative;
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.integration__blocks-bg {
  position: absolute;
  z-index: -1;
  width: 15rem;
  height: 15rem;
}

.integration__blocks-bg.round1 {
  top: -6.4rem;
  left: -4rem;
}

.integration__blocks-bg.round2 {
  bottom: -3rem;
  right: -4.4rem;
  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
  opacity: 0.5;
  border-radius: 50%;
}

.integration__block {
  width: calc((100% / 4) - 3rem);
  max-width: 26.4rem;
  min-height: 15rem;
  margin: 0 1.5rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
  border-radius: 1rem;
}

.integration__block > * {
  max-width: 20rem;
  max-height: 11.2rem;
}

.request {
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request__img {
  width: 100%;
  max-width: 78.5rem;
  margin-left: -19.6rem;
}

.request__form {
  width: 100%;
  max-width: 55.7rem;
}

.request__form-content {
  margin-bottom: 4.2rem;
}

.request__form-content > *:not(:last-child) {
  margin-bottom: 3rem;
}

.request__form-content > h2,
.request__form-content > h3,
.request__form-content > h4,
.request__form-content > h5,
.request__form-content > h6 {
  font: normal 2.4rem / 150% 'Days One', sans-serif;
  color: var(--black2);
}

.request__form-content > p {
  font: 500 1.6rem / 150% 'Montserrat', sans-serif;
  color: var(--blue1);
}

.request__form-inps {
  width: 100%;
  max-width: 46rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.request__form-inps > *:not(:last-child) {
  margin-bottom: 2rem;
}

.request__form-inps > .btn {
  margin-top: 3rem;
}

.footer__wrapper {
  position: relative;
  background-color: var(--black2);
}

.footer__wrapper-bg {
  width: 100%;
  max-width: 86.1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.footer__top {
  position: relative;
  z-index: 1;
  min-height: 30.1rem;
  padding-top: 8rem;
  padding-bottom: 7.9rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer__logo {
  width: 100%;
  max-width: 12rem;
  max-height: 2.7rem;
  margin-right: 7.6rem;
}

.footer__menus {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer__menu:not(:last-child) {
  margin-right: 9rem;
}

.footer__menu-ttl {
  margin-bottom: 3rem;
  display: flex;
}

.footer__menu-ttl > * {
  padding-bottom: 1rem;
  font: 600 1.6rem / 2rem 'Montserrat', sans-serif;
  color: var(--green1);
  border-bottom: 0.1rem solid var(--green1);
  transition: all 0.3s linear;
}

.footer__menu-ttl > *:hover {
  color: var(--white);
  text-shadow: 0 0 0.1rem var(--white);
  border-bottom-color: var(--white);
}

.footer__menu > ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__menu > ul > li:not(:last-child) {
  margin-bottom: 3rem;
}

.footer__menu > ul > li > * {
  font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
  color: var(--white);
  transition: all 0.3s linear;
}

.footer__menu > ul > li > *:hover {
  color: var(--green1);
  text-shadow: 0 0 0.1rem var(--green1);
}

.footer__bottom-wrapper {
  padding: 4rem 0;
  border-top: 0.2rem solid var(--silver2);
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;
}

.footer__bottom > ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__bottom > ul > li:not(:last-child) {
  margin-right: 3.3rem;
}

.footer__bottom > ul > li > * {
  color: var(--white);
  transition: all 0.3s linear;
}

.footer__bottom > ul > li > *:hover {
  color: var(--green1);
  text-shadow: 0 0 0.1rem var(--green1);
}

.footer__copy, .footer__company {
  color: var(--silver1);
}

.footer__company {
  transition: all 0.3s linear;
}

.footer__company:hover {
  color: var(--white);
  text-shadow: 0 0 0.1rem var(--white);
}
