// ---------- TEXT BANNER ---------- //
.text__banner-wrapper {
  padding: 7.2rem 0;
  background-color: var(--black2);
}

.text__banner {
  margin: 0 -8.85rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-content {
	width: calc((100% / 3) - 17.7rem);
	max-width: 26.4rem;
	margin: 0 8.85rem;

	& > * {
	  font: normal 1.6rem / 2rem 'Days One', sans-serif;
	  color: var(--green1);
	  text-align: center;
	}
  }
}

// ---------- TEXT BANNER ---------- //
// ---------- COMPANYS BLOCKS ---------- //
.companys {
  &__blocks-wrapper {
	position: relative;
	padding: 24.6rem 0 7rem;
	background-color: var(--white2);

	&.with_ttl {
	  padding: 10rem 0 7rem;

	  .companys__ttl,
	  .companys__subttl {
		display: block;
	  }
	}

	& > .wrapper {
	  max-width: 98.2rem;
	}
  }

  &__img {
	position: absolute;
	opacity: 0.5;
	border-radius: 50%;
	background: linear-gradient(
			305.73deg,
			var(--green2) -4.29%,
			var(--black3) 96.27%
	);
	transform: matrix(-1, 0, 0, 1, 0, 0);

	&.round1 {
	  width: 2.4rem;
	  height: 2.4rem;
	  top: 7.6rem;
	  left: 40%;
	  transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-50%);
	}

	&.round2 {
	  width: 4.6rem;
	  height: 4.6rem;
	  bottom: 15.9rem;
	  right: 38.6rem;
	}

	&.round3 {
	  width: 3.6rem;
	  height: 3.6rem;
	  bottom: 5rem;
	  left: 38.6rem;
	}
  }

  &__ttl,
  &__subttl {
	display: none;
  }

  &__ttl {
	width: 100%;
	max-width: 75rem;
	margin: 0 auto 6rem;
	padding: 0 1.6rem;
  }

  &__subttl {
	max-width: 75rem;
	margin-bottom: 6rem;
  }

  &__blocks {
	position: relative;
	z-index: 1;
	margin: 0 -1.5rem;
	display: flex;
	flex-wrap: wrap;
  }

  &__block {
	width: calc((100% / 5) - 3rem);
	max-width: 16.8rem;
	margin: 0 1.5rem 3rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-icon {
	  width: 10rem;
	  height: 10rem;
	  margin-bottom: -5.3rem;
	  position: relative;
	  z-index: 1;
	  border-radius: 50%;
	  border: 1rem solid var(--white2);
	  background-color: var(--white);
	  display: flex;
	  justify-content: center;
	  align-items: center;

	  .icon__wrapper {
		max-width: 6rem;
		max-height: 4rem;
	  }
	}

	&-content {
	  padding-top: 6.9rem;
	  background-color: var(--white);
	  border-radius: 2rem;
	  box-shadow: 0.4rem 4rem 5rem rgba(160, 112, 112, 0.15);
	  backdrop-filter: blur(2rem);

	  .content__info {
		margin-bottom: 1.9rem;
		padding: 0 1.8rem;

		& > * {
		  font: 500 1rem / 150% 'Montserrat', sans-serif;
		  color: var(--silver3);
		  text-align: center;
		}
	  }

	  .content__link-wrapper {
		padding: 1.3rem;
		background-color: var(--green3);
		border-radius: 0 0 2rem 2rem;
		display: flex;
		justify-content: center;
		align-items: center;

		& > * {
		  position: relative;
		  padding-right: 1.1rem;
		  font: 600 1.2rem / 1.5rem 'Montserrat', sans-serif;
		  color: var(--green1);
		  transition: all 0.3s linear;

		  &:hover {
			color: var(--blue2);

			&::after {
			  background: url('../img/icons/i-link-arr-blue.svg') center /
                contain no-repeat;
			}
		  }

		  &::after {
			content: '';
			width: 0.6rem;
			height: 0.6rem;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			transition: all 0.3s linear;
			background: url('../img/icons/i-link-arr.svg') center / contain no-repeat;
		  }
		}
	  }
	}
  }
}

// ---------- COMPANYS BLOCKS ---------- //
// ---------- CUSTOMERS BLOCKS ---------- //
.customers__first {
  position: relative;
  padding: 12rem 0 9rem;
  overflow: hidden;

  &.inited_slider {
	padding: 7rem 0 13.3rem;
	background-color: var(--white2);

	.customers__first-slider-arr {
	  display: flex;
	}
  }

  &-img {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
  }

  &-ttl {
	position: relative;
	z-index: 2;
	width: 100%;
	padding: 0 1.6rem;
  }

  &-slider-wrapper {
	width: 100%;
	max-width: 117.8rem;
	margin: 0 auto;
	padding: 0 1.6rem;
	position: relative;
	z-index: 1;

	.customers__first-slider__decor {
	  position: absolute;
	  left: -7rem;
	  top: -6rem;
	  width: 20rem;
	  height: 20rem;

	  img {
		display: block;
		width: 100%;
	  }

	  @media (max-width: 666px) {
		display: none;
	  }
	}

	&.init_blocks {
	  .customers__first-slider {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;

		& > * {
		  width: calc((100% / 3) - 3rem);
		  max-width: 36.2rem;
		  margin: 0 1.5rem 3rem;
		}
	  }
	}

	&.init_slider {
	  .slick-dotted.slick-slider {
		margin-bottom: 0;
	  }

	  .slick-track {
		padding-bottom: 9rem;
	  }

	  .slick-dots {
		bottom: 3.2rem;
	  }
	}
  }

  &-slider {
	.slide {
	  position: relative;
	  width: 100%;
	  min-height: 33rem;
	  max-width: 36.2rem;
	  margin: 0 auto;
	  padding: 3rem;
	  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
	  background-color: var(--white);
	  border-radius: 1rem;

	  & > *:not(:last-child) {
		margin-bottom: 2rem;
	  }

	  &__icon {
		width: 5rem;
		height: 5rem;
		padding: 1rem;
		border-radius: 0.8rem;
		background-color: var(--light-green3);
		display: flex;
		justify-content: center;
		align-items: center;

		& > * {
		  width: 3rem;
		  height: 3rem;
		}
	  }

	  &__ttl {
		min-height: 4rem;
		display: flex;
		align-items: center;

		& > * {
		  font: normal 1.6rem / 2rem 'Days One', sans-serif;
		  color: var(--blue1);
		}
	  }

	  &__content {
		& > *:not(:last-child) {
		  margin-bottom: 2rem;
		}

		& > p {
		  min-height: 5.1rem;
		  line-height: 1.5;
		  display: flex;
		  align-items: center;
		  color: var(--silver3);
		}

		& > ul {
		  & > li {
			position: relative;
			padding-left: 1.6rem;
			font: 500 1.4rem / 1.5 'Montserrat', sans-serif;
			color: var(--silver4);

			&::before {
			  content: '';
			  width: 0.6rem;
			  height: 0.6rem;
			  position: absolute;
			  top: .7rem;
			  left: 0;
			  border-radius: 50%;
			  background-color: var(--green1);
			}

			&:not(:last-child) {
			  margin-bottom: 1.2rem;
			}
		  }
		}
	  }
	}
  }

  &-slider-arr {
	position: absolute;
	bottom: 3.2rem;
	right: 2.8rem;
	display: none;
  }

  &.li-uppercase {
	li {
	  text-transform: uppercase;
	  font-size: 1.2rem;
	}
  }
}

// ---------- CUSTOMERS BLOCKS ---------- //
// ---------- GREEN BLOCKS ---------- //
.green__blocks {
  position: relative;
  padding: 10rem 0 8.8rem;
  background-color: var(--white2);

  &-img {
	position: absolute;

	&.round1,
	&.round2,
	&.round3 {
	  opacity: 0.5;
	  border-radius: 50%;
	  background: linear-gradient(
			  305.73deg,
			  var(--green2) -4.29%,
			  var(--black3) 96.27%
	  );
	}

	&.round1 {
	  width: 3rem;
	  height: 3rem;
	  top: 17.1rem;
	  left: 60%;
	  transform: translateX(-50%);
	}

	&.round2 {
	  width: 10rem;
	  height: 10rem;
	  bottom: 14.5rem;
	  right: 35.5rem;
	}

	&.round3 {
	  width: 5rem;
	  height: 5rem;
	  top: 26.6rem;
	  left: 25.6rem;
	}

	&.lines {
	  width: 100%;
	  max-width: 46.9rem;
	  bottom: 0;
	  left: 0;
	}
  }

  &-ttl {
	width: 100%;
	max-width: 55.8rem;
	margin: 0 auto 6rem;
	padding: 0 1.6rem;
  }

  &-slider-wrapper {
	width: 100%;
	max-width: 117.8rem;
	margin: 0 auto;
	padding: 0 1.6rem;

	&.init_slider {
	  .slick-dotted.slick-slider {
		margin: 0;
	  }

	  .slick-track {
		padding-bottom: 9rem;
	  }

	  .slick-dots {
		bottom: 3.2rem;
	  }
	}

	&.init_blocks {
	  .green__blocks-slider {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;

		& > * {
		  width: calc((100% / 4) - 3rem);
		  max-width: 26.4rem;
		  margin: 0 1.5rem;
		}
	  }
	}
  }

  &-slider {
	position: relative;
	z-index: 1;

	.slide {
	  position: relative;
	  width: 100%;
	  max-width: 26.4rem;
	  margin: 0 auto;
	  padding: 10rem 3rem 4rem;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  border-radius: 2rem;
	  background-color: var(--light-green2);
	  box-shadow: 1rem 7rem 6rem -3.2rem rgba(88, 185, 71, 0.2);
	  backdrop-filter: blur(2rem);
	  overflow: hidden;

	  &::before {
		content: '';
		width: 15.4rem;
		height: 15.4rem;
		position: absolute;
	  }

	  &::after {
		content: '';
		width: 20.8rem;
		height: 20.8rem;
		position: absolute;
	  }

	  &.first {
		&::after {
		  bottom: 0;
		  right: 0;
		  background: url('../img/green-slide-round1.svg') center / contain no-repeat;
		}
	  }

	  &.second {
		&::after {
		  top: -3rem;
		  right: -3rem;
		  background: url('../img/green-slide-round2.svg') center / contain no-repeat;
		}
	  }

	  &.third {
		&::after {
		  width: 20rem;
		  height: 20rem;
		  bottom: -4rem;
		  right: -5.5rem;
		  background: url('../img/green-slide-round3.svg') center / contain no-repeat;
		}
	  }

	  &.fourth {
		&::before {
		  top: -6rem;
		  right: -4rem;
		  background: url('../img/green-slide-round5.svg') center / contain no-repeat;
		}

		&::after {
		  width: 21.2rem;
		  height: 21.2rem;
		  bottom: -6rem;
		  left: -3rem;
		  background: url('../img/green-slide-round4.svg') center / contain no-repeat;
		}
	  }

	  &__ttl {
		position: relative;
		z-index: 1;
		margin-bottom: 2rem;

		& > * {
		  font: normal 1.8rem / 2.3rem 'Days One', sans-serif;
		  color: var(--blue1);
		}
	  }

	  &__more {
		position: relative;
		z-index: 1;
		padding-right: 1.5rem;
		font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
		color: var(--green1);
		transition: all 0.3s linear;

		&:hover {
		  color: var(--blue2);

		  &::after {
			background: url('../img/icons/i-link-arr-blue.svg') center / contain no-repeat;
		  }
		}

		&::after {
		  content: '';
		  width: 0.5rem;
		  height: 0.8rem;
		  position: absolute;
		  top: 50%;
		  right: 0;
		  transform: translateY(-50%);
		  transition: all 0.3s linear;
		  background: url('../img/icons/i-link-arr.svg') center / contain no-repeat;
		}
	  }

	  &__icon {
		position: relative;
		z-index: 1;
		width: 7rem;
		height: 7rem;
		margin: 5.9rem 1rem 0 0;
		align-self: flex-end;
	  }
	}
  }
}

// ---------- GREEN BLOCKS ---------- //
// ---------- TESTIMONIALS ---------- //
.testimonials {
  &__wrapper {
	position: relative;
	padding: 10rem 0 15.3rem;
  }

  &__img {
	position: absolute;
	z-index: -1;

	&.round1,
	&.round2 {
	  border-radius: 50%;
	  background: linear-gradient(
			  305.73deg,
			  var(--green2) -4.29%,
			  var(--black3) 96.27%
	  );
	  opacity: 0.5;
	}

	&.round1 {
	  width: 3rem;
	  height: 3rem;
	  top: 15.2rem;
	  right: 27.6rem;
	}

	&.round2 {
	  width: 8rem;
	  height: 8rem;
	  bottom: 12rem;
	  left: 34rem;
	  transform: matrix(-1, 0, 0, 1, 0, 0);
	}

	&.line {
	  width: 100%;
	  height: 60%;
	  top: 0;
	  right: 0;
	}
  }

  &__ttl {
	margin-bottom: 6rem;
  }

  &__slider-wrapper {
	width: 100%;
	max-width: 154.2rem;
	margin-left: auto;
	position: relative;
  }

  &__slider {
	width: 100%;
	max-width: 154.2rem;
	margin-left: auto;

	.slide {
	  display: flex;
	  align-items: flex-start;

	  &__video {
		position: relative;
		width: 100%;
		max-width: 65.5rem;
		height: 44.9rem;
		margin-right: 3rem;
		border-radius: 2rem;

		&-poster {
		  width: 100%;
		  height: 100%;
		  position: absolute;
		  z-index: 2;
		  top: 0;
		  left: 0;
		}

		&-play {
		  width: 10rem;
		  height: 10rem;
		  position: absolute;
		  z-index: 2;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  cursor: pointer;
		}

		& > video {
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		  object-position: 50% 50%;
		  display: block;
		}

		& > *,
		& > * > * {
		  border-radius: inherit;
		}
	  }

	  &__content {
		width: 100%;
		max-width: 45.9rem;

		&-name {
		  margin-bottom: 1rem;

		  & > * {
			font: 600 1.8rem / 2.2rem 'Montserrat', sans-serif;
			color: var(--blue1);
		  }
		}

		&-position {
		  margin-bottom: 2rem;

		  & > * {
			font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;
			color: var(--silver3);
		  }
		}

		&-text {
		  & > * {
			font: italic 500 1.8rem / 150% 'Montserrat', sans-serif;
			color: var(--silver3);
		  }
		}
	  }
	}
  }

  &__navigation {
	width: 100%;
	max-width: 85rem;
	position: absolute;
	bottom: -5.3rem;
	right: 0;

	.slide {
	  width: 100%;
	  max-width: 26.4rem;

	  &__poster {
		width: 100%;
		max-width: 26.4rem;
		height: 14.8rem;
		position: relative;
		border-radius: 0.8rem;
		margin-bottom: 1.4rem;
		cursor: pointer;

		&::before {
		  content: '';
		  width: 100%;
		  height: 100%;
		  position: absolute;
		  top: 0;
		  left: 0;
		  border-radius: inherit;
		  background-color: var(--white);
		  opacity: 0.5;
		}

		&-play {
		  width: 8rem;
		  height: 8rem;
		  position: absolute;
		  z-index: 2;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		}

		&-img {
		  width: 100%;
		  height: 100%;
		  object-fit: cover;
		  object-position: 50% 50%;
		  display: block;
		  border-radius: inherit;
		}
	  }

	  &__name {
		margin-bottom: 1rem;

		& > * {
		  font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
		  color: var(--blue1);
		}
	  }

	  &__position {
		& > * {
		  font: normal 1rem / 1.2rem 'Montserrat', sans-serif;
		  color: var(--silver3);
		}
	  }
	}
  }

  &__slider-arr {
	position: absolute;
	bottom: -5.3rem;
	left: 61.5rem;
  }
}

// ---------- TESTIMONIALS ---------- //
// ---------- RESOURCES ---------- //
.resources {
  &__wrapper {
	position: relative;
	padding: 10rem 0 6.8rem;
	background-color: var(--white2);
  }

  &__bg {
	position: absolute;
	z-index: 1;

	&.round1,
	&.round2,
	&.round3 {
	  opacity: 0.5;
	  border-radius: 50%;
	  background: linear-gradient(
			  305.73deg,
			  var(--green2) -4.29%,
			  var(--black3) 96.27%
	  );
	}

	&.round1 {
	  width: 2.8rem;
	  height: 2.8rem;
	  top: 6.5rem;
	  left: 56%;
	  transform: translateX(-50%) rotate(-180deg);
	}

	&.round2 {
	  width: 6.4rem;
	  height: 6.4rem;
	  bottom: 21.3rem;
	  right: 28.9rem;
	}

	&.round3 {
	  width: 2rem;
	  height: 2rem;
	  top: 39.3rem;
	  left: 26.1rem;
	  transform: matrix(-1, 0, 0, 1, 0, 0);
	}

	&.img1,
	&.img2 {
	  width: 100%;
	  height: 100%;
	  max-width: 20.4rem;
	  max-height: 20.4rem;
	}

	&.img1 {
	  top: 19rem;
	  right: 28.2rem;
	}

	&.img2 {
	  bottom: 10.8rem;
	  left: 27.4rem;
	}
  }

  &__ttl {
	margin-bottom: 4.7rem;
	padding: 0 1.6rem;
  }

  &__navigation {
	position: relative;
	z-index: 1;
	margin-bottom: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 0.1rem solid var(--white);

	& > * {
	  position: relative;
	  margin: 0 4rem;
	  padding-bottom: 2.5rem;
	  transition: all 0.3s linear;
	  color: var(--silver3);
	  text-align: center;

	  &::after {
		content: '';
		width: 0;
		height: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.3s linear;
		background-color: var(--green1);
	  }

	  &.active,
	  &:hover {
		color: var(--green1);

		&::after {
		  width: 12rem;
		}
	  }

	  &.active {
		cursor: default;
	  }
	}
  }

  &__blocks {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 122.8rem;
	min-height: 63rem;
	margin: 0 auto;
	padding: 0 1.6rem;
  }

  &__block {
	display: none;

	&.slick-dotted.slick-slider {
	  margin-bottom: 0;
	}

	.slick-list {
	  padding-bottom: 9rem;
	}

	.slick-dots {
	  bottom: 3.2rem;
	}

	.block {
	  width: 100%;
	  max-width: 36.2rem;
	  margin: 0 auto;
	  background-color: var(--white);
	  border-radius: 2rem;
	  box-shadow: 10px 70px 60px -32px var(--light-green);
	  backdrop-filter: blur(2rem);

	  &__img {
		border-radius: 2rem 2rem 0 0;

		& > * {
		  border-radius: inherit;
		}
	  }

	  &__content-wrapper {
		padding: 3rem;
	  }

	  &__subttl {
		margin-bottom: 2rem;

		& > * {
		  font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;
		  color: var(--silver4);
		  text-transform: uppercase;
		}
	  }

	  &__ttl {
		margin-bottom: 1.4rem;
		display: flex;

		& > * {
		  font: normal 1.8rem / 150% 'Days One', sans-serif;
		  color: var(--blue1);
		  text-transform: uppercase;
		}
	  }

	  &__text {
		margin-bottom: 2.4rem;

		& > * {
		  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
		  color: var(--silver3);
		}
	  }

	  &__info {
		margin-bottom: 3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > * {
		  position: relative;
		  padding-left: 2.6rem;
		  font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
		  color: var(--silver4);
		  text-decoration: underline;

		  &::before {
			content: '';
			width: 1.6rem;
			height: 1.6rem;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		  }

		  &.date::before {
			background: url('../img/icons/i-calendar.svg') center / contain no-repeat;
		  }

		  &.time::before {
			background: url('../img/icons/i-clock.svg') center / contain no-repeat;
		  }
		}
	  }

	  &__more {
		position: relative;
		padding-right: 1.5rem;
		font: 600 1.4rem / 1.7rem 'Montserrat', sans-serif;
		color: var(--green1);
		transition: all 0.3s linear;

		&:hover {
		  color: var(--blue2);

		  &::after {
			background: url('../img/icons/i-link-arr-blue.svg') center / contain no-repeat;
		  }
		}

		&::after {
		  content: '';
		  width: 0.5rem;
		  height: 0.8rem;
		  position: absolute;
		  right: 0;
		  top: 53%;
		  transform: translateY(-50%);
		  transition: all 0.3s linear;
		  background: url('../img/icons/i-link-arr.svg') center / contain no-repeat;
		}
	  }
	}
  }
}

// ---------- RESOURCES ---------- //
// ---------- TEXT BLOCKS ---------- //
.text__blocks {
  position: relative;
  padding: 12rem 0 9.3rem;

  &.white_blocks {
	padding: 8rem 0 7.3rem;
	background-color: var(--white2);

	.text__blocks-ttl {
	  max-width: 88.2rem;
	  margin: 0 auto 6rem;
	}

	.text__blocks-slider {
	  .slide {
		max-width: 26.4rem;
		min-height: 15rem;
		padding: 4.8rem 3rem;

		&__content {
		  width: 100%;
		  max-width: 20.4rem;
		  margin: 0 auto;

		  & > * {
			font: 600 1.8rem / 150% 'Montserrat', sans-serif;
			color: var(--blue1);
		  }
		}
	  }
	}
  }

  &.green_blocks {
	padding: 10rem 0 9.3rem;

	.text__blocks-ttl {
	  margin: 0 auto 4rem;
	}

	.text__blocks-subttl {
	  & > * {
		font-size: 1.6rem;
	  }
	}

	.text__blocks-slider {
	  .slide {
		max-width: 26.4rem;
		min-height: 14rem;
		padding: 4.3rem 4rem;
		background-color: var(--light-green2);

		&.first,
		&.second,
		&.third,
		&.fourth {
		  &::after {
			content: '';
			position: absolute;
		  }
		}

		&.first {
		  &::after {
			bottom: 0;
			right: 0;
			width: 13.7rem;
			height: 13.7rem;
			background: url('../img/text-blocks-green-round1.svg') center / contain no-repeat;
		  }
		}

		&.second {
		  &::after {
			top: 0;
			left: 0;
			width: 10rem;
			height: 13.7rem;
			background: url('../img/text-blocks-green-round2.svg') center / contain no-repeat;
		  }
		}

		&.third {
		  &::after {
			bottom: 0;
			right: 0;
			width: 13.7rem;
			height: 7rem;
			background: url('../img/text-blocks-green-round3.svg') center / contain no-repeat;
		  }
		}

		&.fourth {
		  &::after {
			top: 0;
			right: 0;
			width: 13.7rem;
			height: 13rem;
			background: url('../img/text-blocks-green-round4.svg') center / contain no-repeat;
		  }
		}

		&__content {
		  & > * {
			font: normal 1.8rem / 150% 'Days One', sans-serif;
			color: var(--blue1);
		  }
		}
	  }
	}
  }

  &-bg {
	width: 100%;
	height: 60%;
	position: absolute;
	top: 0;
	left: 0;
  }

  &-ttl,
  &-subttl {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 75.4rem;
	padding: 0 1.6rem;
  }

  &-ttl {
	margin: 0 auto 3rem;
  }

  &-subttl {
	max-width: 70rem;
	margin: 0 auto 6rem;

	& > * {
	  font-size: 2rem;
	}
  }

  &-slider-wrapper {
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 122.5rem;
	margin: 0 auto;
	padding: 0 1.6rem;
  }

  &-slider {
	&-img {
	  position: absolute;

	  &.round {
		bottom: -.5rem;
		left: -5rem;
		width: 20rem;
		height: 20rem;
		border-radius: 50%;
		background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
		opacity: 0.5;
	  }

	  &.line_round {
		top: -10.8rem;
		right: -7.7rem;
		width: 25rem;
		height: 25rem;
	  }
	}

	.slick-list {
	  padding-bottom: 6rem;
	}

	.slide {
	  position: relative;
	  width: 100%;
	  max-width: 36.2rem;
	  min-height: 26rem;
	  margin: 0 auto;
	  padding: 6.7rem 6.8rem;
	  background-color: var(--white);
	  box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.08);
	  border-radius: 1rem;
	  display: flex;
	  justify-content: center;
	  align-items: center;

	  &__content {
		& > * {
		  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
		  color: var(--silver3);
		  text-align: center;
		}
	  }
	}

	&-arr {
	  position: absolute;
	  bottom: 0.7rem;
	  right: 3.6rem;
	}
  }
}

// ---------- TEXT BLOCKS ---------- //
// ---------- ICON BLOCKS ---------- //
.with__icons-wrapper {
  position: relative;
  padding: 10rem 0 7rem;
  background-color: var(--white2);
  overflow: hidden;

  &.bg_white {
	background-color: var(--white);

	.with__icons-ttl {
	  max-width: 81.4rem;
	  margin: 0 auto 4rem;
	}

	.with__icons-wrapper-bg {
	  top: 0;
	  left: 0;
	  bottom: auto;
	  width: 100%;
	  height: 100%;
	}
  }

  &-bg {
	position: absolute;
	bottom: -17rem;
	left: -8rem;
	width: 35.8rem;
	height: 35.8rem;
  }
}

.with__icons {
  &-ttl {
	width: 100%;
	max-width: 65.4rem;
	margin: 0 auto 6.5rem;
  }

  &-subttl {
	width: 100%;
	max-width: 65.1rem;
	margin: 0 auto 6rem;
  }

  &-blocks {
	position: relative;
	margin: 0 -1.5rem;
	display: flex;
	flex-wrap: wrap;

	&-round {
	  position: absolute;
	  top: -6.4rem;
	  right: -6.3rem;
	  width: 15rem;
	  height: 15rem;
	  border-radius: 50%;
	  background: linear-gradient(305.73deg, rgba(105, 244, 80, 0.2) 16.66%, rgba(255, 255, 255, 0) 96.27%);
	  opacity: 0.6;
	}

	.block {
	  width: calc((100% / 4) - 3rem);
	  margin: 0 1.5rem 3rem;
	  padding: 3rem;
	  background-color: var(--white);
	  box-shadow: .4rem 4rem 5rem rgba(88, 185, 71, 0.15);
	  backdrop-filter: blur(2rem);
	  border-radius: 1.6rem;
	  display: flex;
	  flex-direction: column;
	  align-items: center;

	  &__icon {
		width: 100%;
		max-width: 12rem;
		height: 12rem;
		margin-bottom: 3rem;
		border-radius: 1.2rem;
		background-color: var(--light-green2);
		display: flex;
		justify-content: center;
		align-items: center;

		& > * {
		  width: 5rem;
		  height: 5rem;
		}
	  }

	  &__content {
		& > * {
		  font: 500 1.4rem / 150% 'Montserrat', sans-serif;
		  text-align: center;
		  text-transform: uppercase;
		  color: var(--blue1);
		}
	  }
	}

  }

}

// ---------- ICON BLOCKS ---------- //
// ---------- TEXT DESCRIPTION ---------- //
.text__desc-wrapper {
  padding: 10rem 0;

  &.desc_reverse {
	background-color: var(--white);

	.text__desc {
	  flex-direction: row-reverse;
	}

	.text__desc-content {
	  margin-right: 0;
	  margin-left: 1.9rem;
	}

	.text__desc-img {
	  max-width: 67rem;
	  margin-right: 0;
	  margin-left: -10rem;
	}
  }
}

.text__desc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-content {
	width: 100%;
	max-width: 55.8rem;
	margin-right: 1.9rem;

	& > * {
	  &:not(:last-child) {
		margin-bottom: 2.5rem;
	  }
	}

	& > h2 {
	  font: bold 3.4rem / 4rem 'Work Sans', sans-serif;
	  letter-spacing: -0.02em;
	  color: var(--black2);
	}

	& > h3 {
	  font: normal 2.4rem / 150% 'Days One', sans-serif;
	  color: var(--blue1);
	}

	& > h5 {
	  font: normal 1.6rem / 2rem 'Days One', sans-serif;
	  letter-spacing: -0.02em;
	  color: var(--blue1);
	}

	& > h6 {
	  font: 500 1.6rem / 2rem 'Montserrat', sans-serif;
	  letter-spacing: -0.02em;
	  color: var(--silver3);
	}

	& > p {
	  font: 500 1.6rem / 150% 'Montserrat', sans-serif;
	  color: var(--blue1);
	}

	& > ul {
	  & > li {
		position: relative;
		padding-left: 1.8rem;
		font: normal 1.4rem / 150% 'Montserrat', sans-serif;
		color: var(--silver3);

		&::before {
		  content: '';
		  position: absolute;
		  top: .5rem;
		  left: 0;
		  width: 0.8rem;
		  height: 0.8rem;
		  border-radius: 50%;
		  background-color: var(--green1);
		}

		&:not(:last-child) {
		  margin-bottom: 2.5rem;
		}
	  }
	}
  }

  &-img {
	width: 100%;
	max-width: 60rem;
	margin-right: -3.1rem;
  }
}

// ---------- TEXT DESCRIPTION ---------- //
// ---------- BANNER ---------- //
.banner {
  position: relative;
  padding: 10rem 0;

  &.with_img {
	padding: 8rem 0;

	.banner__content {
	  max-width: 78.6rem;
	}

	.banner__content-ttl {
	  margin-bottom: 3rem;
	}

	.banner__content-txt {
	  width: 100%;
	  max-width: 55.8rem;
	  margin: 0 auto 4rem;

	  & > * {
		font: 500 1.6rem / 150% 'Montserrat', sans-serif;
	  }
	}
  }

  &__img {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: var(--black5);
	}
  }

  &__video {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&.active {
	  position: fixed;
	  z-index: 5;

	  &::after {
		display: none;
	  }

	  .banner__video-close {
		opacity: 1;
		pointer-events: visible;
	  }
	}

	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: var(--black5);
	}

	&-close {
	  position: absolute;
	  z-index: 1;
	  top: 4rem;
	  right: 20%;
	  width: 4rem;
	  height: 4rem;
	  opacity: 0;
	  pointer-events: none;
	}
  }

  &__content {
	width: 100%;
	max-width: 68.6rem;
	margin: 0 auto;
	padding: 0 1.6rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-ttl,
	&-txt {
	  & > * {
		color: var(--white);
	  }
	}

	&-ttl {
	  margin-bottom: 4rem;

	  & > * {
		mark {
		  color: var(--green1);
		}
	  }
	}

	&-subttl {
	  & > * {
		font: bold 1.8rem / 150% 'Montserrat', sans-serif;
		color: var(--white);
		text-align: center;
	  }
	}

	&-txt {
	  margin-bottom: 7rem;

	  & > * {
		font: 500 2rem / 2.4rem 'Montserrat', sans-serif;
		letter-spacing: -0.02em;
		text-align: center;
	  }
	}
  }
}

// ---------- BANNER ---------- //
// ---------- MISS ---------- //
.miss__wrapper {
  padding: 10rem 0 7rem;
  overflow: hidden;
}

.miss {
  &__top {
	margin-bottom: 6rem;
  }

  &__ttl {
	position: relative;
	width: 100%;
	max-width: 69rem;
	margin: 0 auto 4rem;

	&-round {
	  position: absolute;
	  z-index: -1;
	  top: -3.4rem;
	  left: -0.8rem;
	  width: 8rem;
	  height: 8rem;
	  border-radius: 50%;
	  background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
	  opacity: 0.5;
	  transform: rotate(-180deg);
	}

	&-img {
	  position: absolute;
	  width: 8rem;
	  height: 8rem;
	  left: -6rem;
	  top: -4rem;

	  img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	  }
	}
  }

  &__blocks {
	position: relative;
	margin: 0 -1.5rem;
	display: flex;
	flex-wrap: wrap;

	&-bg {
	  position: absolute;
	  z-index: -1;
	  top: -10rem;
	  right: -9.5rem;
	  width: 30rem;
	  height: 30rem;
	}
  }

  &__block {
	width: calc((100% / 2) - 3rem);
	//min-height: 50.8rem;
	margin: 0 1.5rem 3rem;
	padding: 4rem;
	background-color: var(--white);
	box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	&-img {
	  width: 100%;
	  margin-bottom: 3rem;

	  & > * {
		width: 100%;
		max-width: 47.8rem;
		max-height: 27rem;
	  }
	}

	&-ttl {
	  margin-bottom: 2rem;

	  & > * {
		font: normal 1.6rem / 2rem 'Days One', sans-serif;
		color: var(--blue1);
		text-align: center;
	  }
	}

	&-txt {
	  & > * {
		font: 500 1.4rem / 150% 'Montserrat', sans-serif;
		color: var(--silver3);
		text-align: center;
	  }
	}
  }
}

.miss--2 {
  .miss__ttl {
	margin-bottom: 4rem;
  }

  .miss__block-ttl {
	order: -1;
  }

  .miss__blocks-bg {
	width: 25rem;
	height: 25rem;
  }
}

// ---------- MISS ---------- //
// ---------- SMALL TEXT ---------- //
.small__text-wrapper {
  position: relative;
  padding: 8rem 0;
  background-color: var(--white2);
  overflow: hidden;
}

.small__text {
  max-width: 98.2rem;

  &-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }

  & > * {
	font: normal 3.6rem / 4.6rem 'Days One', sans-serif;
	color: var(--black2);
	text-align: center;
  }
}

// ---------- SMALL TEXT ---------- //
// ---------- SEVERAL DESCRIPTIONS ---------- //
.several__descs-wrapper {
  position: relative;
  padding: 10rem 0;

  &.bg_green {
	background-color: var(--white2);
  }

  &.several_reverse {
	.several__descs-bg {
	  &.round1 {
		width: 5rem;
		height: 5rem;
		top: 7rem;
		left: 16.7%;
		right: auto;
		transform: rotate(-180deg);
	  }

	  &.round2 {
		top: 52%;
		left: 55%;
		transform: translate(-50%, -50%);
	  }

	  &.round3 {
		width: 7rem;
		height: 7rem;
		top: 19.9rem;
		bottom: auto;
		right: 21%;
		transform: unset;
	  }

	  &.round4 {
		width: 10rem;
		height: 10rem;
		bottom: 6.9rem;
		right: 16%;
		transform: matrix(1, 0, 0, -1, 0, 0);
	  }
	}

	.several__descs-blocks {
	  & > *:not(:last-child) {
		margin-bottom: 10rem;
	  }
	}

	.text__desc {
	  flex-direction: row-reverse;

	  &-content {
		margin-right: 0;
		margin-left: 9.8rem;
	  }

	  &:nth-child(even) {
		flex-direction: row;

		.text__desc {
		  &-img {
			margin-right: -11rem;
			margin-left: 0;
		  }

		  &-content {
			margin-right: 9.8rem;
			margin-left: 0;
		  }
		}
	  }
	}
  }
}

.several__descs {
  &-bg {
	position: absolute;
	border-radius: 50%;
	background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
	opacity: 0.5;

	&.round1,
	&.round3 {
	  width: 6rem;
	  height: 6rem;
	}

	&.round1 {
	  top: 14.7rem;
	  right: 23%;
	  transform: matrix(1, 0, 0, -1, 0, 0);
	}

	&.round2 {
	  top: 50%;
	  left: 17.2rem;
	  transform: translateY(-50%) matrix(-1, 0, 0, 1, 0, 0);
	  width: 10rem;
	  height: 10rem;
	}

	&.round3 {
	  bottom: 6.4rem;
	  right: 15%;
	  transform: matrix(1, 0, 0, -1, 0, 0);
	}
  }

  &-ttl {
	width: 100%;
	max-width: 75.2rem;
	margin: 0 auto 6rem;
  }

  &-blocks {
	display: flex;
	flex-direction: column;

	& > *:not(:last-child) {
	  margin-bottom: 6rem;
	}

	& > *:nth-child(even) {
	  & {
		flex-direction: row-reverse;
	  }

	  .text__desc-content {
		margin-right: 0;
		margin-left: 9.8rem;
		max-width: 46rem;
	  }

	  .text__desc-img {
		margin-right: 0;
		margin-left: -1.2rem;
	  }
	}

	.text__desc-content {
	  margin-right: 9.8rem;
	  max-width: 46rem;
	}

	.text__desc-img {
	  margin-right: -1.2rem;
	}
  }
}

// ---------- SEVERAL DESCRIPTIONS ---------- //
// ---------- TEST TESTIMONIALS ---------- //
.text__testimonials-wrapper {
  position: relative;
  padding: 8rem 0 5.3rem;
  overflow: hidden;
}

.text__testimonials {
  &-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0;
  }

  &-ttl {
	position: relative;
	width: 100%;
	max-width: 20.5rem;
	padding: 0 1.6rem;
	margin: 0 auto 6rem;

	&-round {
	  position: absolute;
	  z-index: -1;
	  top: -4.8rem;
	  left: -4.1rem;
	  width: 12rem;
	  height: 12rem;
	}
  }

  &-slider-wrapper {
	position: relative;
	width: 100%;
	max-width: 121rem;
	margin: 0 auto;
	padding: 0 1.6rem;

	.slick-track {
	  padding-bottom: 8rem;
	}
  }

  &-slider {
	&-arr {
	  position: absolute;
	  z-index: 1;
	  bottom: 2.7rem;
	  right: 3.2rem;
	}

	.slide {
	  width: 100%;
	  max-width: 26.4rem;
	  margin: 0 auto;
	  background-color: var(--white);
	  box-shadow: .5rem 3rem 2rem rgba(88, 185, 71, 0.15);
	  backdrop-filter: blur(2rem);
	  border-radius: 1rem;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;

	  &:hover {
		.slide__quote {
		  svg {
			path {
			  fill: var(--green4);
			}
		  }
		}
	  }

	  & > * {
		&:not(:last-child) {
		  margin-bottom: 2rem;
		}
	  }

	  &__quote {
		padding: 3rem 0 0 3rem;

		svg {
		  path {
			transition: all .3s linear;
		  }
		}
	  }

	  &__content {
		min-height: 8.4rem;
		padding: 0 2rem;

		& > * {
		  font: normal 1.4rem / 150% 'Montserrat', sans-serif;
		  color: var(--silver3);
		}
	  }

	  &__more {
		padding: 0 2rem;
		font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
		text-decoration: underline;
		color: var(--green5);
		align-self: flex-end;
		transition: all .3s linear;
		cursor: pointer;

		&:hover {
		  color: var(--green1);
		}
	  }

	  &__person {
		width: 100%;
		padding: 1.5rem 2rem 1.7rem;
		display: flex;
		align-items: center;
		border-radius: 0 0 1rem 1rem;
		background-color: var(--green6);

		&-img {
		  width: 100%;
		  max-width: 3.8rem;
		  min-width: 3.8rem;
		  height: 3.8rem;
		  margin-right: 2.1rem;
		  border-radius: 50%;

		  & > * {
			border-radius: inherit;
		  }
		}

		&-info {
		  display: flex;
		  flex-direction: column;

		  .info__name {
			margin-bottom: 0.5rem;
			font: normal 1.6rem / 2rem 'Days One', sans-serif;
			color: var(--blue1);
		  }

		  .info__position {
			font: 500 1.1rem / 1.3rem 'Montserrat', sans-serif;
			color: var(--silver3);
		  }
		}
	  }
	}
  }
}

// ---------- TEST TESTIMONIALS ---------- //
// ---------- INTEGRATION ---------- //
.integration__wrapper {
  position: relative;
  padding: 10rem 0 7rem;
}

.integration {
  &__ttl {
	margin-bottom: 6rem;
  }

  &__blocks {
	position: relative;
	margin: 0 -1.5rem;
	display: flex;
	flex-wrap: wrap;

	&-bg {
	  position: absolute;
	  z-index: -1;
	  width: 15rem;
	  height: 15rem;

	  &.round1 {
		top: -6.4rem;
		left: -4rem;
	  }

	  &.round2 {
		bottom: -3rem;
		right: -4.4rem;
		background: linear-gradient(305.73deg, var(--green2) -4.29%, var(--black3) 96.27%);
		opacity: 0.5;
		border-radius: 50%;
	  }
	}
  }

  &__block {
	width: calc((100% / 4) - 3rem);
	max-width: 26.4rem;
	min-height: 15rem;
	margin: 0 1.5rem 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--white);
	box-shadow: 0 1.5rem 3rem rgba(88, 185, 71, 0.05);
	border-radius: 1rem;

	& > * {
	  max-width: 20rem;
	  max-height: 11.2rem;
	}
  }
}

// ---------- INTEGRATION ---------- //
// ---------- FORM REQUEST ---------- //
.request {
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__img {
	width: 100%;
	max-width: 78.5rem;
	margin-left: -19.6rem;
  }

  &__form {
	width: 100%;
	max-width: 55.7rem;

	&-content {
	  margin-bottom: 4.2rem;

	  & > *:not(:last-child) {
		margin-bottom: 3rem;
	  }

	  & > h2,
	  & > h3,
	  & > h4,
	  & > h5,
	  & > h6 {
		font: normal 2.4rem / 150% 'Days One', sans-serif;
		color: var(--black2);
	  }

	  & > p {
		font: 500 1.6rem / 150% 'Montserrat', sans-serif;
		color: var(--blue1);
	  }
	}

	&-inps {
	  width: 100%;
	  max-width: 46rem;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;

	  & > *:not(:last-child) {
		margin-bottom: 2rem;
	  }

	  & > .btn {
		margin-top: 3rem;
	  }
	}
  }
}

// ---------- FORM REQUEST ---------- //
// ---------- FOOTER ---------- //
.footer {
  &__wrapper {
	position: relative;
	background-color: var(--black2);

	&-bg {
	  width: 100%;
	  max-width: 86.1rem;
	  position: absolute;
	  top: 0;
	  right: 0;
	}
  }

  &__top {
	position: relative;
	z-index: 1;
	min-height: 30.1rem;
	padding-top: 8rem;
	padding-bottom: 7.9rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
  }

  &__logo {
	width: 100%;
	max-width: 12rem;
	max-height: 2.7rem;
	margin-right: 7.6rem;
  }

  &__menus {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
  }

  &__menu {
	&:not(:last-child) {
	  margin-right: 9rem;
	}

	&-ttl {
	  margin-bottom: 3rem;
	  display: flex;

	  & > * {
		padding-bottom: 1rem;
		font: 600 1.6rem / 2rem 'Montserrat', sans-serif;
		color: var(--green1);
		border-bottom: 0.1rem solid var(--green1);
		transition: all 0.3s linear;

		&:hover {
		  color: var(--white);
		  text-shadow: 0 0 0.1rem var(--white);
		  border-bottom-color: var(--white);
		}
	  }
	}

	& > ul {
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;

	  & > li {
		&:not(:last-child) {
		  margin-bottom: 3rem;
		}

		& > * {
		  font: 500 1.2rem / 1.5rem 'Montserrat', sans-serif;
		  color: var(--white);
		  transition: all 0.3s linear;

		  &:hover {
			color: var(--green1);
			text-shadow: 0 0 0.1rem var(--green1);
		  }
		}
	  }
	}
  }

  &__bottom-wrapper {
	padding: 4rem 0;
	border-top: 0.2rem solid var(--silver2);
  }

  &__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font: normal 1.2rem / 1.5rem 'Montserrat', sans-serif;

	& > ul {
	  display: flex;
	  justify-content: center;
	  align-items: center;

	  & > li {
		&:not(:last-child) {
		  margin-right: 3.3rem;
		}

		& > * {
		  color: var(--white);
		  transition: all 0.3s linear;

		  &:hover {
			color: var(--green1);
			text-shadow: 0 0 0.1rem var(--green1);
		  }
		}
	  }
	}
  }

  &__copy,
  &__company {
	color: var(--silver1);
  }

  &__company {
	transition: all 0.3s linear;

	&:hover {
	  color: var(--white);
	  text-shadow: 0 0 0.1rem var(--white);
	}
  }
}

// ---------- FOOTER ---------- //
